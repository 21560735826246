<template>
  <b-card>
    <b-row v-show="directors.length > 0 || search != null">
      <b-col md="7">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Exibir"
              label-for="h-exibir"
              label-cols-md="4"
              class="text-muted"
            >
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                :disabled="isLoading"
                size="sm"
                class="form-content"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="3">
        <b-input-group class="input-group-merge">
          <b-input-group-prepend is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            placeholder="Pesquisar"
            debounce="800"
            type="search"
            size="sm"
            :disabled="isLoading"
          />
        </b-input-group>
      </b-col>
      <b-col md="2">
        <b-button
          v-b-toggle.sidebar-create-director
          size="sm"
          block
          variant="relief-primary"
          @click="addNew"
        >
          Novo Diretor
          <feather-icon icon="GridIcon" />
        </b-button>
      </b-col>
    </b-row>
    <div v-show="directors.length > 0 || search != null">
      <b-overlay
        :show="isLoading"
        opacity="0.70"
        variant="transparent"
        rounded="sm"
      >
        <b-table-simple
          striped
          responsive
          class="mt-2"
          id="tableDirectors"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th class="text-left">Data de cadastro</b-th>
              <b-th>Nome</b-th>
              <b-th>CPF</b-th>
              <b-th>E-Mail</b-th>
              <b-th>Escola</b-th>
              <b-th>Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(tr, indextr) in directors" :key="indextr">
              <b-td>
                {{ tr.created_at | dateFormat }}
              </b-td>
              <b-td class="text-center">
                {{ tr.name }}
              </b-td>
              <b-td class="text-center">
                {{ tr.cpf }}
              </b-td>
              <b-td class="text-center">
                {{ tr.email }}
              </b-td>
              <b-td class="text-center">
                {{ tr.school ? tr.school.name : 'Escola não encontrada' }}
              </b-td>
              <b-td class="text-center">
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  v-b-toggle.sidebar-create-director
                  @click="updateDetail(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-dark"
                  class="btn-icon rounded-circle"
                  @click="showDetail(tr.id)"
                  v-b-modal.modal-delete
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div class="row" v-show="totalRows > 10">
          <div class="col-6 col-md-9"> 
            Exibindo {{ perPage }} de {{ totalRows }} registros
          </div>
          <div class="col-6 col-md">
            <b-pagination
              v-model="currentPage"
              class="text-center float-right"
              :per-page="perPage"
              :total-rows="totalRows"
              aria-controls="tableSchools"
              size="sm"
              @change="handlePageChange"
            />
          </div>
        </div>
      </b-overlay>
    </div>
    <div class="text-center" v-show="directors.length == 0 && search == null">
      <b-overlay
        :show="isLoading"
        opacity="0.70"
        variant="transparent"
        rounded="sm"
      >
        <h2>Nenhum diretor foi encontrado! 🤖</h2>
        <p>
          <small class="text-muted">Comece adicionando um diretor!</small>
        </p>
        <b-button
          variant="relief-primary"
          @click="addNew"
          v-b-toggle.sidebar-create-director
          size="sm"
        >
          Novo diretor
          <feather-icon icon="GridIcon" />
        </b-button>
      </b-overlay>
    </div>
    <create-edit :key="count" :dataProp="sidebarData" />

    <!-- Modal Delete -->
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete"
      centered
      title="Deletar"
      v-if="directorModel != null"
    >
      <b-card-text>
        <p>
          Você está preste a deletar este diretor do sistema:
          <b>{{ directorModel.name }}</b>
        </p>
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
        >
          Concordo em excluir este diretor
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == false"
            @click="deleteDirector(directorModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>

    <!-- Modal license -->
    <b-modal
      id="modal-license"
      ok-variant="primary"
      modal-class="modal-primary"
      ref="modal-license"
      centered
      title="Gerenciar licenças"
      v-if="showDirectorDetails"
    >
      <b-card-text>
        <p>
          A empresa <b>{{ showDirectorDetails.company.company_name }}</b> tem
          <b>{{ showDirectorDetails.company.remaining_licences }}</b> licenças
          isponíveis de
          <b> {{ showDirectorDetails.company.number_of_licences }} </b>.
        </p>
        <b-form-group
          label="Selecione a quantidade de licenças."
          label-for="i-sb-maxmin"
          class="pl-1 pr-1"
        >
          <b-form-spinbutton
            id="sb-maxmin"
            v-model="license.number_of_licences"
            wrap
            min="1"
            :max="showDirectorDetails.company.remaining_licences"
          />
        </b-form-group>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-primary"
            block
            @click="provideLicense((license.director_id = showDirectorDetails.id))"
          >
            Disponibilizar licenças
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </b-card>
</template>
<script>
/* eslint-disable */
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CreateEdit from "./CreateEdit.vue";

export default {
  components: {
    BCard,
    CreateEdit
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      count: 1,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,
      isLoading: true,

      license: {
        number_of_licences: null,
        director_id: null
      },

  
      DeleteStatus: false,
      directorModel: null,
      showDirectorDetails: null,

      sidebarData: {
        id: null,
        name: null,
        number_of_licences: null,
        administrative_dependency: null,
        address: {}
      },
      sidebarActive: false, 

      perPageOptions: [
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 20, text: "20" },
      ],

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    };
  },
  computed: {
    ...mapState("directors", ["directors", "directorShow"])
  },
  methods: {
    ...mapActions("directors", ["fetchDetails", "setLicense"]),

    addNew() {
      this.sidebarData = {}; 
      this.count++;
    },
    
    fetchDirectors(paginated = true, page = 1){
      this.isLoading = true
      this.$store
        .dispatch("directors/fetchDirectors", {
          paginate: paginated,
          page: page,
          generic: this.search,
          per_page: this.perPage
        })
        .then((response) => {
          this.totalRows = response.total
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    handlePageChange(value) {
      this.fetchDirectors(true, value)
    },

    showDirector(id){
      this.$store
        .dispatch("directors/fetchDetails", {
          director_id: id
        })
        .then((response) => {
          this.showDirectorDetails = response
          this.license.number_of_licences = response.number_of_licences
        }) 
        .finally(() => {
        })
    },

    provideLicense(){
     this.setLicense(this.license)
      .then((response) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Licença liberada com sucesso',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          }
        })
      }) 
      .finally(() => {
        this.fetchDirectors()
        this.$refs['modal-license'].hide()
      })
    },

    showDetail(id){
      this.directorModel = this.$store.getters['directors/getDirectorById'](id)
    },

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    deleteDirector(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("directors/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.fetchDirectors()
          this.$refs['modal-delete'].hide()
        })
    },

    updateDetail(id){
      this.count++;
      this.updateModel = this.$store.getters['directors/getDirectorById'](id);
      this.sidebarData.id = this.updateModel.id;
      this.sidebarData.name = this.updateModel.name;
      this.sidebarData.cpf = this.updateModel.cpf;
      this.sidebarData.email = this.updateModel.email;
      this.sidebarData.school_id = this.updateModel.school_id;
    },
  },
  created() {
    this.fetchDirectors();
  },
  watch: {
    perPage(){
      this.fetchDirectors()
    },
    search(){
      this.currentPage = 1;
      this.fetchDirectors(true, 1);
    }
  },
};
</script>
<style lang="scss">
.form-content {
  width: 5rem;
}
.form-content-search {
  width: 11rem;
}
</style>